import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import ArticleLayout from '../components/article-layout'
import SEO from '../components/seo'
import Layout from '../components/layout'

const InstruoTermsCondition = (props) => {
  return (
    <Layout>
      <SEO title='Instruo Terms and Condition' />
      <ArticleLayout>
        <Typography variant='h1'>Terms & Conditions</Typography>
        <Typography variant='body1'>
          By downloading or using my applications, games or websites, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using my games, applications or websites. You’re not allowed to copy, or modify any games, applications or website, any part of the games, applications or websites, or my trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The applications, games and websites themselves, and all the trademarks, copyright, database rights and other intellectual property rights related to it, still belong to Tomas Sjösten.
        </Typography>
        <Typography variant='body1'>
          Tomas Sjösten is committed to ensuring that the applications, games and websites is as useful and efficient as possible. For that reason, I reserve the right to make changes to the applications, games and websites or to charge for its services, at any time and for any reason. I will never charge you for the applications, games or websites or its services without making it very clear to you exactly what you’re paying for.
        </Typography>
        <Typography variant='body1'>
          My applications, games and websites locally stores and processes personal data that you have provided to me, in order to provide our applications, games or websites. It’s your responsibility to keep your devices and access to the applications, games or websites secure. I therefore recommend that you do not jailbreak or root your devices, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your devices vulnerable to malware/viruses/malicious programs, compromise your devices security features and it could mean that our applications, games or websites won’t work properly or at all.
        </Typography>
        <Typography variant='body1'>
          My applications, games and websites use third party services that declare their own Terms and Conditions.
        </Typography>
        <Typography variant='body1'>
          Link to Terms and Conditions of third party service providers used by my applications, games and websites
          <ul>
            <li><Link href="https://policies.google.com/terms">Google Play Services</Link></li>
            <li><Link href='https://firebase.google.com/terms/analytics'>Google Analytics for Firebase</Link></li>
            <li><Link href='https://firebase.google.com/terms/crashlytics'>Firebase Crashlytics</Link></li>
          </ul>
        </Typography>

        <Typography variant='body1'>
          You should be aware that there are certain things that Tomas Sjösten will not take responsibility for. Certain functions of our applications, games or websites will require an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but Tomas Sjösten cannot take responsibility for his applications, games or websites not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.
        </Typography>
        <Typography variant='body1'>
          If you’re using my applications, games or websites outside of an area with Wi-Fi, you should remember that
          your terms of the agreement with your mobile network provider will still apply.
          As a result, you may be charged by your mobile provider for the cost of data for the duration of
          the connection while accessing my applications, games or websites, or other third party charges. In using my applications, games or websites, you’re accepting responsibility for any such charges, including roaming data charges if you use my applications, games or websites outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using our applications, games or websites, please be aware that I assume that you have received permission from the bill payer for using our applications, games or websites.
        </Typography>
        <Typography variant='body1'>
          Along the same lines, Tomas Sjösten cannot always take responsibility for the way you use his applications, games or websites i.e. You need to make sure that your devices stays charged – if it runs out of battery and you can’t turn it on to avail the Service, Tomas Sjösten cannot accept responsibility.
        </Typography>
        <Typography variant='body1'>
          With respect to Tomas Sjösten responsibility for your use of his applications, games or websites, when you’re using his applications, games or websites, it’s important to bear in mind that although he endeavour to ensure that it is updated and correct at all times, he do rely on third parties to provide information to him so that he can make it available to you. Tomas Sjösten accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of his applications, games or websites.
        </Typography>
        <Typography variant='body1'>
          At some point, he may wish to update his applications, games or websites. His applications, games or websites is currently available in the browser and Android & iOS – the requirements for these systems(and for any additional systems we decide to extend the availability of his applications, games or websites to) may change, and you’ll need to download the updates if you want to keep using his applications, games or websites. Tomas Sjösten does not promise that we will always update his applications, games or websites so that it is relevant to you and/or works with the browser, Android or iOS version that you have installed on your devices. However, you promise to always accept updates to the application when offered to you, Tomas Sjösten may also wish to stop providing his applications, games or websites, and may terminate use of it at any time without giving notice of termination to you. Unless Tomas Sjösten tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using our applications, games or websites, and (if needed) delete it from your devices.
        </Typography>

        <Typography variant='h2'>Changes to This Terms and Conditions</Typography>
        <Typography variant='body1'>
          Tomas Sjösten may update his Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. Tomas Sjösten will notify you of any changes by posting the new Terms and Conditions on this page.
        </Typography>
        <Typography variant='body1'>
          These terms and conditions are effective as of 2020-06-04
        </Typography>

        <Typography variant='h2'>Contact Us</Typography>
        <Typography variant='body1'>
          If you have any questions or suggestions about my Terms and Conditions, do not hesitate to contact me at <Link href='mailto:tomas.sjosten@gmail.com'>tomas.sjosten@gmail.com</Link>.
        </Typography>
      </ArticleLayout>
    </Layout>
  )
}

export default InstruoTermsCondition
